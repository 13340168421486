exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-1-spaces-md": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/1-getting-started/1-spaces.md" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-1-spaces-md" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-2-installations-md": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/1-getting-started/2-installations.md" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-2-installations-md" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-3-user-management-md": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/1-getting-started/3-user-management.md" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-3-user-management-md" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-4-networking-md": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/1-getting-started/4-networking.md" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-4-networking-md" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-content-management-1-introduction-md": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-content-management/1-introduction.md" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-content-management-1-introduction-md" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-content-management-2-tags-md": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-content-management/2-tags.md" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-content-management-2-tags-md" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-content-management-3-channels-md": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-content-management/3-channels.md" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-content-management-3-channels-md" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-content-management-4-segments-md": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-content-management/4-segments.md" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-content-management-4-segments-md" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-content-management-5-content-player-md": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-content-management/5-content-player.md" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-content-management-5-content-player-md" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-marketplace-apps-1-queue-management-md": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/3-marketplace-apps/1-queue-management.md" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-marketplace-apps-1-queue-management-md" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-marketplace-apps-2-signage-playlist-md": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/3-marketplace-apps/2-signage-playlist.md" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-marketplace-apps-2-signage-playlist-md" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-marketplace-apps-3-endless-aisle-md": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/3-marketplace-apps/3-endless-aisle.md" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-marketplace-apps-3-endless-aisle-md" */)
}

